import React from 'react';
import forDirection from '../../hoc/for-direction';

export const ArrowBackIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" {...props}>
    <path d="M5.95685444,9 L14.8,9 C14.9104569,9 15,9.08954305 15,9.2 L15,9.8 C15,9.91045695 14.9104569,10 14.8,10 L5.87157268,10 L8.80832611,12.9367534 C8.88643097,13.0148583 8.88643097,13.1414913 8.80832611,13.2195961 L8.38406204,13.6438602 C8.30595719,13.7219651 8.17932419,13.7219651 8.10121933,13.6438602 L4.14142136,9.68406223 C4.10236893,9.6450098 4.08284271,9.59382534 4.08284271,9.54264088 C4.08284271,9.49145641 4.10236893,9.44027195 4.14142136,9.40121952 L8.10121933,5.44142155 C8.17932419,5.36331669 8.30595719,5.36331669 8.38406204,5.44142155 L8.80832611,5.86568562 C8.88643097,5.94379047 8.88643097,6.07042347 8.80832611,6.14852833 L5.95685444,9 Z" />
  </svg>
);

export const ArrowBackIconRtl = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" viewBox="0 0 19 19" {...props}>
    <path
      d="M5.96695729,9 L14.8828427,9 C14.9932997,9 15.0828427,9.08954305 15.0828427,9.2 L15.0828427,9.8 C15.0828427,9.91045695 14.9932997,10 14.8828427,10 L5.9031385,10 L8.854916,12.9517775 C8.93361354,13.030475 8.93409401,13.1575885 8.85598915,13.2356934 L8.43172508,13.6599574 C8.35362022,13.7380623 8.22650676,13.7375818 8.14780922,13.6588843 L4.15796309,9.66903816 C4.11861432,9.62968939 4.09881982,9.57823664 4.09862562,9.52685797 C4.09843142,9.47547931 4.11783752,9.42417473 4.15688994,9.3851223 L8.11668792,5.42532432 C8.19479278,5.34721946 8.32190624,5.34769993 8.40060378,5.42639747 L8.82808729,5.85388098 C8.90678484,5.93257853 8.9072653,6.05969199 8.82916044,6.13779685 L5.96695729,9 Z"
      transform="translate(9.582843, 9.542641) scale(-1, 1) translate(-9.582843, -9.542641) "
    />
  </svg>
);

export default forDirection(ArrowBackIcon, ArrowBackIconRtl);
